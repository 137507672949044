<template>
  <ValidationObserver ref="observer" rules="required" v-slot="{ handleSubmit, validate }">
    <RightSideBar
      v-if="isOpen"
      @close="onClose"
      :loading="isLoading"
      @submit="handleSubmit(onSubmit(customLeaveItems))"
      submit="Save"
      button-class="bg-dynamicBackBtn text-white rounded"
      :disabled-button="isLoading"
      width="width:520px"
    >
      <template v-slot:title>
        <p class="text-darkPurple"> Time Of/Leave Category(s)</p>
      </template>
      <template v-slot:subtitle>
        <div class="flex flex-col justfy-start gap-5 mb-5">
          <p class="font-semibold text-darkPurple">Add, Edit or remove leave categories.</p>
          <p class="font-medium text-jet">Note that you cannot remove default categories.</p>
        </div>
      </template>
      <div class="flex flex-col justify-start gap-5">
        <div class="flex flex-col space-y-2">
          <div v-for="item in defaultLeaveItems" :key="item.name" class="w-full p-2 even:bg-ghostWhite rounded-md">
            <span class="inline-block">{{ item.name }}</span>
          </div>
          <div v-for="(item, index) in customLeaveItems" :key="index" class="w-full even:bg-ghostWhite rounded-md">
            <div class="w-full flex justify-between items-center gap-3 px-2 py-2">
              <div class="w-full text-jet text-base">
                <span class="text-jet text-base" v-if="!item.active">{{ item.name }}</span>
                <c-text :rules="['required']" v-else class="category_name" placeholder="Enter" v-model="item.name" />
              </div>
              <span v-if="item.active" @click="$delete(item, 'active'), editCategoryItem(item)">
                <Icon icon-name="save" size="xsm" class-name="text-flame cursor-pointer" />
              </span>
              <span v-else class="flex justify-start items-center gap-2">
                <span @click="$set(item, 'active', true)">
                  <Icon icon-name="edit" size="xsm" class-name="text-blueCrayola cursor-pointer" />
                </span>
                <span @click="delCategoryModal(item)">
                  <Icon icon-name="delete" class-name="text-desire cursor-pointer" size="xsm" />
                </span>
              </span>
            </div>
          </div>
        </div>
        <div class="flex flex-row justify-between items-center">  
          <div class="flex flex-col justify-start gap-0 pl-2">
            <c-text placeholder="Enter" style="width: 295px;" variant="w-full h-10" label="Category Name" v-model="categoryItem" :rules="['required']" />
          </div>
          <div @click="addCategoryItem(validate)" 
            class="flex justify-start align-center gap-2 cursor-pointer pr-2"
            :style="{marginTop}"
          >
            <Icon size="xsm" icon-name="icon-plus" class="text-flame" />
            <span class="text-flame text-base font-semibold">Add New</span>
          </div>
        </div>
      </div>
      <c-modal v-if="isOpenDeleteModal">
        <c-card class="flex flex-col gap-5 max-w-max p-5">
          <div class="flex text-center justify-start gap-2">
            <p class="font-bold text-jet text-base">Are you sure you want to delete</p>
            <p class="font-bold text-flame text-base">{{ activeLeaveItem.name }}</p>
            <p class="font-bold text-jet text-base">Category?</p>
          </div>
          <div class="flex justify-center gap-4">
            <c-button label="Yes" variant="primary btn-bg c-button" @onClick="deleteCategory" />
            <c-button label="Cancel" variant="secondary  c-button btn-border" @onClick="isOpenDeleteModal = false" />
          </div>
        </c-card>
      </c-modal>
    </RightSideBar>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate"
import CText from "@scelloo/cloudenly-ui/src/components/text"
import CButton from "@scelloo/cloudenly-ui/src/components/button"
import CCard from "@scelloo/cloudenly-ui/src/components/card"
import CModal from "@/components/Modal"
import RightSideBar from "@/components/RightSideBar"

export default {
  components: {
    ValidationObserver,
    RightSideBar,
    CText,
    CButton,
    CCard,
    CModal
  },

  data() {
    return {
      isOpen: false,
      isLoading: false,
      isOpenDeleteModal: false,
      marginTop: `20px`,

      categoryItem: null,
      defaultLeaveItems: [],
      customLeaveItems: [],
      activeLeaveItem: {},
    };
  },
  watch:{
    categoryItem(){
      if(this.categoryItem){
        this.marginTop = `20px`
      }else{
        this.marginTop = `0px`
      }
    }
  },
  methods: {
    onOpen(){
      this.isLoading = true
      this.getOrgLeaveCategories()
      this.isOpen = true
    },
    onClose(){
      this.categoryItem = null
      this.activeLeaveItem = {}
      this.customLeaveItems = []
      this.isLoading = false
      this.isOpen = false
      this.$emit('close')
    },
    async addCategoryItem(useValidation){
      this.marginTop = !this.categoryItem && `0px`
      const isValidated = await useValidation()

      if (isValidated) {
        const findIndex = this.customLeaveItems.findIndex(v => v.name === this.categoryItem)
        if (findIndex === -1) {
          this.customLeaveItems.push({ name: this.categoryItem })
          this.categoryItem = null
        } else this.$toasted.info('Category already exists!', { duration: 3000 })
      }
    },
    editCategoryItem(categoryItem){
      if (Object.hasOwn(categoryItem, 'id')){
        this.$_editLeaveCategory(categoryItem).then(({ data }) => {
          this.$toasted.success(data.message, { duration: 3000 })
        }).catch((error) => {
          this.$toasted.error(error.message, { duration: 3000 });
        })
      }
    },
    async onSubmit(categoryItems) {
      const payload = categoryItems.filter((item) => !Object.hasOwn(
        item, 'id'))?.map((item) => ({ ...item, orgId: this.$orgId })
      )

      try {
        await this.$handlePrivilege("timeOffHR", "addNewLeaveCategory")
        this.isLoading = true
        this.$_leaveCategories(payload).then(({ data }) => {
          this.$toasted.success(data.message, { duration: 3000 })
          document.querySelector(".rightSidebar").scrollTo({ top: 0, behavior: 'smooth' })
          this.onClose()
          this.$emit('success')
        }).catch((error) => {
          this.$toasted.error(error.message, { duration: 3000 })
          document.querySelector(".rightSidebar").scrollTo({ top: 0, behavior: 'smooth' })
          this.isLoading = false
        })
      } catch (error) {
        const message = "You do not have permission to perform this task"
        this.$toasted.error(message, { duration:3000 })
      }
    },

    async delCategoryModal(activeLeaveItem) {
      try {
        await this.$handlePrivilege("timeOffHR", "deleteLeaveCategory")
        this.activeLeaveItem = activeLeaveItem
        this.isOpenDeleteModal = true
      } catch (error) {
        const message = "You do not have permission to perform this task"
        this.$toasted.error(message, { duration: 3000 })
      }
    },

    deleteCategory() {
      this.isOpenDeleteModal = false;
      if (Object.hasOwn(this.activeLeaveItem, 'id')) {
        this.isLoading = true
        this.$_deleteLeaveCategory(this.activeLeaveItem.id).then(({ data }) => {
          this.customLeaveItems = this.customLeaveItems.filter(v => v.id !== this.activeLeaveItem.id)
          document.querySelector(".rightSidebar").scrollTo({ top: 0, behavior: 'smooth' })
          this.$toasted.success(data.message, { duration: 3000 })
          this.isLoading = false
        }).catch((error) => {
          document.querySelector(".rightSidebar").scrollTo({ top: 0, behavior: 'smooth' })
          this.$toasted.error(error.message, { duration: 3000 })
          this.isLoading = false
        })
      } else {
        document.querySelector(".rightSidebar").scrollTo({ top: 0, behavior: 'smooth' })
        this.customLeaveItems = this.customLeaveItems.filter(v => v.name !== this.activeLeaveItem.name)
      }
    },
    async getOrgLeaveCategories() {
      this.isLoading = true
      await this.$_getLeaveCategory().then(({ data }) => {
        this.defaultLeaveItems = data.filter(item => item.isDefault)
        this.customLeaveItems = data.filter(item => !item.isDefault)
        this.isLoading = false
      }).catch((error) => {
        this.$toasted.error(error.message, { duration: 3000 })
        this.isLoading = false
      });
    },
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
::v-deep .category_name label {
  display: none !important;
}

.btn-bg {
    background-color: var(--dynamic-Background) !important;
 }

.c-button {
  width: 150px;
  padding: 10px 40px !important;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-border{
  color: var(--dynamic-Background) !important;
  border: 1px solid var(--dynamic-Background) !important;
}
</style>