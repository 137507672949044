<template>
  <div>
    <error-page v-if="showError" />
    <div v-else>
      <loader v-if="isLoading" size="xxs" :loader-image="false" style="height:100%" />
      <div v-else class="flex flex-col gap-2 w-full px-0 py-3">
        <div class="flex justify-end pt-3">
          <div class="flex justify-start items-center">
            <c-button
              label="Add"
              variant="primary"
              class="button_class btn-bg"
              @onClick="handleAddCategory"
            />
          </div>
        </div>
        
        <div class="line"></div>

        <c-tabs
          class="mt-2 mx-2 overflow-x-auto"
          :tabs="tabs"
          :active-tab="leaveCategoryTabs[0]"
          @currentTab="onChangeTab"
          :border="true"
          fontSize="14px"
          padding="3px 20px"
        />
        <div class="flex justify-end py-3">
          <div class="flex justify-start items-center">
            <c-button
              label="Create New"
              background-color="none"
              variant="secondary"
              class="button_class btn-border"
              @onClick="handleEditCategory({ setting: {}, activeTabItem })"
            />
          </div>
        </div>
        
        <c-card>
          <card-footer
            reloadcard
            class="px-6 pb-6"
            @reload="getCategorySettings()"
            @sortType="$queryBuilder({ sort: $event }, getCategorySettings)"
            @searchResult="$queryBuilder({ search: $event }, getCategorySettings)"
          />
        </c-card>
        <c-table
          :headers="tableHeader"
          aria-label="time off table"
          :items="activeTableItems"
          :has-number="false"
          :loading="isTableLoading"
          v-if="activeTableItems.length || isTableLoading"
        >
          <template v-slot:item="{ item }">
            <div v-if="item.level" class="text-sm text-darkPurple">
              <div v-if="Array.isArray(item.data.level)">
                <span v-if="item.data.isAll">All</span>
                <span v-else-if="item.data.level.length === 1">{{ item.data.level[0]['name'] }}</span>
                <span v-else>{{ item.data.level[0]['name'] }}, +{{ item.data.level.length -1 }}</span>
              </div>
              <span v-else class="capitalize">{{ item.data.level }}</span>
            </div>
            <span v-if="item.maximumApplicableDays" class="text-sm text-darkPurple">
              {{ item.data.maximumApplicableDays }} Days, {{ item.data.paySettingId ? 'Paid': 'Unpaid' }}
            </span>
            <span v-if="item.hasAllowance" class="text-sm text-darkPurple">
              {{ item.data.hasAllowance ? 'Yes' : 'No' }}
            </span>
            <div v-if="item.createdBy" class="flex justify-start items-center gap-2">
              <div v-if="item.data.createdBy" class="flex justify-start items-center gap-2">
                <img v-if="item.data.createdBy.photo" :src="item.data.createdBy.photo" alt="profile pic" class="w-9 h-9 rounded" />
                <div v-else class="w-9 h-9 rounded text-blueCrayola border text-center font-semibold pt-2">
                    {{ $getInitials(`${item.data.createdBy.fname} ${item.data.createdBy.lname}`) }}
                </div>
                <div class="flex flex-col justify-start gap-0">
                  <p class="text-darkPurple text-sm">{{ item.data.createdBy.fname }} {{ item.data.createdBy.lname }}</p>
                  <p v-if="item.data.createdAt" class="text-darkPurple text-xs text-flame">
                    {{ $DATEFORMAT(new Date(item.data.createdAt), "MM-dd-yyyy") }}
                  </p>
                  <p v-if="item.data.dateCreated" class="text-darkPurple text-xs text-flame">
                    {{ $DATEFORMAT(new Date(item.data.dateCreated), "MM-dd-yyyy") }}
                  </p>
                </div>
              </div>
              <span v-else class="text-sm text-jet">N/A</span>
            </div>
            <div v-if="item.modifiedBy" class="flex justify-start items-center gap-2">
              <div v-if="item.data.modifiedBy" class="flex justify-start items-center gap-2">
                <div v-if="item.data.modifiedBy">
                  <img v-if="item.data.modifiedBy.photo" :src="item.data.modifiedBy.photo" alt="profile pic" class="w-9 h-9 rounded" />
                  <div v-else class="w-9 h-9 rounded text-blueCrayola border text-center font-semibold pt-2">
                    <template v-if="item.data.modifiedBy">
                      {{ $getInitials(`${item.data.modifiedBy.fname} ${item.data.modifiedBy.lname}`) }}
                    </template>
                  </div>
                </div>
                <div v-if="item.data.modifiedBy" class="flex flex-col justify-start gap-0">
                  <p class="text-darkPurple text-sm">{{ item.data.modifiedBy.fname }} {{ item.data.modifiedBy.lname }}</p>
                  <p v-if="item.data.updatedAt" class="text-darkPurple text-xs text-flame">
                    {{ $DATEFORMAT(new Date(item.data.updatedAt), "MM-dd-yyyy") }}
                  </p>
                  <p v-else-if="item.data.lastModified" class="text-darkPurple text-xs text-flame">
                    {{ $DATEFORMAT(new Date(item.data.lastModified), "MM-dd-yyyy") }}
                  </p>
                </div>
              </div>
              <span v-else class="text-sm text-jet">N/A</span>
            </div>
            <span v-if="item.isEnabled" class="text-sm text-darkPurple">
              {{ item.data.isEnabled ? 'Active' : 'Inactive' }}
            </span>
            <div v-if="item.isSetup">
              <Menu left style-name="" class="my-2">
                <template v-slot:title>
                  <Icon icon-name="more_icon" size="xs" />
                </template>
                <div class="w-28 h-18 px-2 py-2 flex flex-col justify-start items-start gap-2">
                  <div @click="handleEditSettings({ setting: item.data, activeTabItem })" class="w-full">
                    <div class="w-full px-2 py-1 rounded-md flex justify-start items-center gap-2 cursor-pointer hover:bg-cultured">
                      <Icon icon-name="edit" class-name="text-blueCrayola" size="xs" />
                      <span class="text-sm font-normal leading-5 text-darkPurple">Edit</span>
                    </div>
                  </div>
                  <div @click="$refs.timeOffSettingsRef.onOpen({ setting: item.data, activeTabItem }, 'view')" class="w-full">
                    <div class="w-full px-2 py-1 rounded-md flex justify-start items-center gap-2 cursor-pointer hover:bg-cultured">
                      <Icon icon-name="icon-eye" class-name="text-flame" size="xs" />
                      <span class="text-sm font-normal leading-5 text-darkPurple">View</span>
                    </div>
                  </div>
                </div>
              </Menu>
            </div>
          </template>
        </c-table>
        <div v-else class="flex flex-col justify-center items-center gap-0 px-10">
          <Icon icon-name="paygrade_Illustration" size="" class-name="w-56 h-56" />
          <p class="font-normal text-base leading-5 text-jet text-center w-3/4">
            An overview of leave types can be found here. This is empty now,
            but you can start adding leave types to populate this area.
          </p>
        </div>
      </div>
      <LeaveCategories
        ref="leaveCategoriesRef"
        @close="getCategorySettings"
        @success="getLeaveCategory"
      />
      <TimeOffSetting
        ref="timeOffSettingsRef"
        @close="getCategorySettings"
        @success="getLeaveSettingsCatgory"
      />
    </div>
  </div>
</template>

<script>
import CTabs from "@scelloo/cloudenly-ui/src/components/tab"
import CTable from "@scelloo/cloudenly-ui/src/components/table"
import CButton from "@scelloo/cloudenly-ui/src/components/button"
import CCard from "@scelloo/cloudenly-ui/src/components/card"
import Loader from "@scelloo/cloudenly-ui/src/components/loader"
import Menu from "@scelloo/cloudenly-ui/src/components/Menu"
import CardFooter from "@/components/CardFooter"
import ErrorPage from "@/modules/Admin/error403"
import LeaveCategories from "./LeaveCategories"
import TimeOffSetting from "./TimeOffSetting"

export default {
  components: {
    CTable,
    CButton,
    CTabs,
    Menu,
    Loader,
    CardFooter,
    CCard,
    ErrorPage,
    LeaveCategories,
    TimeOffSetting
  },
  data() {
    return {
      isLoading: false,
      isTableLoading: false,
      isCreateNew:false,

      setQueryTimeout: '',
      queryParams: {},
      activeTabItem: {},
      activeTableItems: [],

      leaveCategoryTabs: [],
      showError: false,

      tableHeader: [
        { title: "Level", value: "level" },
        { title: "entitlements", value: "maximumApplicableDays" },
        { title: "leave allowance", value: "hasAllowance" },
        { title: "Created By", value: "createdBy" },
        { title: "Modified By", value: "modifiedBy" },
        { title: "Status", value: "isEnabled" },
        { title: "", value: "isSetup", image: true, filter: true }
      ],
    }
  },
  methods: {
    async onChangeTab(tab){
      this.activeTabItem = this.leaveCategories?.find(v => v.name === tab.name)
      await this.getCategorySettings({ activeId: this.activeTabItem?.id || 1 })
    },

    async getCategorySettings(queryParams) {
      this.isCreateNew = false;
      this.isTableLoading = true
      const payload = {activeId: this.activeTabItem?.id || 1, queryParams}
      await this.$_getOrgLeaveCategorySettings(payload).then(({ data }) => {
        this.activeTableItems = data.settings.map(item => ({
          ...item, level: Array.isArray(item.level) ? item.level : [{name: 'All', id: item.level }]
        }))
        this.isTableLoading = false
      }).catch((error) => {
        this.$toasted.error(error, { duration: 3000 })
        this.isTableLoading = false
      })
    },

    async handleEditSettings({ setting, activeTabItem }) {
      try {
        await this.$handlePrivilege("timeOffHR", "editLeaveSettings");
        this.$refs.timeOffSettingsRef.onOpen({ setting, activeTabItem }, 'edit')
      } catch (error) {
        const message = "You do not have permission to perform this task"
        this.$toasted.error(message, { duration: 3000 })
      }
    },
    async handleEditCategory({ setting, activeTabItem }) {
      try {
        await this.$handlePrivilege("timeOffHR", "editLeaveCategories")
        this.isCreateNew = true;
        this.$refs.timeOffSettingsRef.onOpen({ setting, activeTabItem }, 'edit')
      } catch (error) {
        const message = "You do not have permission to perform this task"
        this.$toasted.error(message, { duration: 3000 })
      }
    },
    async handleSettings() {
      try {
        await this.$handlePrivilege("timeOffHR", "editLeaveCategories");
        this.$refs.timeOffSettingsRef.onOpen({ activeTabItem: this.activeTabItem }, 'edit')
      } catch (error) {
        const message = "You do not have permission to perform this task"
        this.$toasted.error(message, { duration: 3000 })
      }
    },
    // async handleEditCategory() {
    //   try {
    //     await this.$handlePrivilege("timeOffHR", "editLeaveCategories")
    //     this.$refs.leaveCategoriesRef.onOpen(this.leaveCategories)
    //   } catch (error) {
    //     const message = "You do not have permission to perform this task"
    //     this.$toasted.error(message, { duration: 3000 })
    //   }
    // },
    async handleAddCategory() {
      try {
        await this.$handlePrivilege("timeOffHR", "addNewLeaveCategory")
        this.$refs.leaveCategoriesRef.onOpen(this.leaveCategories)
      } catch (error) {
        const message = "You do not have permission to perform this task"
        this.$toasted.error(message, { duration: 3000 })
      }
    },
    async getLeaveCategory() {
      this.isLoading = true
      await this.$_getLeaveCategory().then(({ data }) => {
        this.leaveCategories = data
        this.leaveCategoryTabs = data?.map(item => ({name: item.name, isEnabled: item.isSetup}))
        this.isLoading = false;
        this.isCreateNew = false;
      })
    },
    async getLeaveSettingsCatgory(){
      if(this.isCreateNew){
        await this.getLeaveCategory()
      } 
      await this.getCategorySettings()
    },
    async bootstrapModule(){
      await this.getLeaveCategory()
    }
  },
  computed: {
    plan() {
      if (this.$store.state.subscription) {
        return this.$store.state.subscription.plan
      } return null
    },
    tabs(){
      return this.leaveCategoryTabs
    }
  },

  async mounted() {
    if(this.plan !== 'basic'){
      try {
       await this.$handlePrivilege("timeOffHR", "accessTimeOffSettings");
       this.showError = false
       this.bootstrapModule()
     } catch (error) {
       this.showError = true
     }
    }
  }
};
</script>

<style lang="scss" scoped>
.tag {
  border-radius: 5px;

  width: fit-content;
  padding: 2px 7px;
}
.enabled-x {
  background: rgba(19, 181, 106, 0.15);
  color: #27ae60;
}
.enabled-xx {
  background: rgba(33, 118, 255, 0.15);
  color: #2176ff;
}
.disabled-x {
  background: rgba(135, 142, 153, 0.15);
  color: #333333;
}
.btn-bg {
    background-color: var(--dynamic-Background) !important;
 }
.button_class {
    padding: 10px 40px !important;
    font-weight: 600;
    display: flex;
    justify-self: end;
}

.btn-border{
  color: var(--dynamic-Background) !important;
  border: 1px solid var(--dynamic-Background) !important;
}

.line{
  border: 1px solid #C2C7D6;
  width: 100%;
  height: 0;
  margin: 16px auto;
  border-radius: 10px;
  padding: 0;
}
</style>